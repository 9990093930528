import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import sanitizeHtml from 'sanitize-html'
import Layout from 'components/Layout'
import { Box, Text, Heading, Container } from '@chakra-ui/react'

const PrivacyPolicy: FC = () => {
  const { t } = useTranslation()
  const arrayLength = [...Array(4).keys()]

  return (
    <Layout>
      <Container
        bg="black.600"
        color="white"
        mt={{ base: '4.25rem', lg: '5rem' }}
      >
        <Box py={{ base: '4rem', md: '6rem' }} textAlign="center">
          <Text color="primary" fontWeight="600" mb="3">
            {t('privacyPolicy.subtitle')}
          </Text>
          <Heading size="lg" as="h1">
            {t('privacyPolicy.title')}
          </Heading>
        </Box>
      </Container>
      <Container>
        <Box
          py={{ base: '3rem', lg: '6rem' }}
          color="gray.400"
          maxW="720px"
          mx="auto"
        >
          {arrayLength.map(val => (
            <div key={val}>
              {t(`privacyPolicy.content.${val}.title`) !== '' && (
                <Text
                  as="h2"
                  m="3rem 0 1.5rem"
                  size="xxl"
                  color="black.400"
                  fontWeight="600"
                >
                  {t(`privacyPolicy.content.${val}.title`)}
                </Text>
              )}
              <Text
                size="lg"
                sx={{
                  a: {
                    textDecoration: 'underline',
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    t(`privacyPolicy.content.${val}.text`, {
                      interpolation: { escapeValue: false },
                    })
                  ),
                }}
              />
            </div>
          ))}
        </Box>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default PrivacyPolicy
